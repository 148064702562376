import { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";

import { IntegratedAutomation } from "./contents/HMI/Integrated-automation";
import { AtexRating } from "./contents/HMI/Atex-rating";
import { Configuration } from "./contents/HMI/Configuration";
import { DisplacementPumps } from "./contents/HMI/Displacement-pumps";
// import { HazardousLocationsRated } from "./contents/HMI/Hazardous-locations-rated";
import { InlineDilution } from "./contents/HMI/Inline-dilution";
import { InlineMonitoring } from "./contents/HMI/Inline-monitoring";
import { NxGenCartridge } from "./contents/HMI/NxGen-cartridge";

const Content: FC = (): JSX.Element => {
    const { activeSubLink } = useStore();
    switch (activeSubLink.id) {
        case SubLinkId.integratedAutomation:
            return <IntegratedAutomation />;
        case SubLinkId.atexRating:
            return <AtexRating />;
        case SubLinkId.configuration:
            return <Configuration />;
        case SubLinkId.displacementPumps:
            return <DisplacementPumps />;
        // case SubLinkId.hazardousLocationsRated:
        //     return <HazardousLocationsRated />;
        case SubLinkId.inlineDilution:
            return <InlineDilution />;
        case SubLinkId.inlineMonitoring:
            return <InlineMonitoring />;
        case SubLinkId.nxGenCartridge:
            return <NxGenCartridge />;
        default:
            return <></>;
    }
};

const SidebarContent: FC = (): JSX.Element => {
    return (
        <div className="c-sidebar__wrapper">
            <Content />
        </div>
    );
};

export default SidebarContent;
