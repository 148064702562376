import React, { Fragment, useEffect, useState } from "react";
import { Title, Text, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import { playHotspot } from "src/services/annotations";
import { changeMaterialOpacityByName } from "src/services/materials-textures";

export const InlineMonitoring: React.FC = () => {
    const [state, setState] = useState<{ name: string; active: boolean }[]>([
        // { name: "In-line process monitoring", active: false },
        { name: "Flow meter", active: false },
        { name: "Pressure transducer", active: false },
        { name: "Temperature sensors", active: false },
    ]);

    const {
        activeSubLink: { text, extras },
        sidebarStatus,
    } = useStore();

    useEffect(() => {
        setState([
            // { name: "In-line process monitoring", active: true },
            { name: "Flow meter", active: false },
            { name: "Pressure transducer", active: false },
            { name: "Temperature sensors", active: false },
        ]);

        const index = state.map((x) => x.active).indexOf(true);
        extras?.changeMaterials &&
            changeMaterialOpacityByName(extras?.changeMaterials![index], 0);
    }, [sidebarStatus]);

    const onClickAnnotation = (index: number) => {
        // extras?.annotations && playHotspot(extras.annotations![index]);
        // changeMaterialOpacityByName(extras.changeMaterials![index], 0.2);
        if (extras?.changeMaterials) {
            extras.changeMaterials.map((mat) => {
                mat !== extras.changeMaterials![index]
                    ? changeMaterialOpacityByName(mat, 0)
                    : changeMaterialOpacityByName(mat, 0.2);
            });

            let updatedState = state.map((item, i) => {
                return { ...item, active: i === index ? true : false };
            });
            setState(updatedState);
        }
    };

    return (
        <>
            <Title title={text} />

            <div className="c-sidebar__body">
                <Text text="The flow meter, pressure transducer and temperature sensor enable in-line process monitoring and control of critical process parameters (CPPs) in real time." />
                <Spacer x={3} />
                {extras?.annotations &&
                    extras.annotations.map((annot, index) => (
                        <Fragment key={index}>
                            <button
                                onClick={() => onClickAnnotation(index)}
                                className={`button button--secondary button--large button--fixwidth-large 
                            ${state[index].active && "button--hover"}`}
                            >
                                {state[index].name}
                            </button>
                            <Spacer />
                        </Fragment>
                    ))}
            </div>
        </>
    );
};
