import React from "react";
import { resetCameraAnnotation } from "src/constants";
import {
    playCustomAnimation,
    resetAnimationFrame,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "../../store";
import { SidebarVisibility, SubLink } from "../../types/index.d";
import SidebarContent from "./Sidebar.content.component";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
    const {
        activeSubLink,
        sidebarStatus,
        setSidebarStatus,
        animationIsPlaying,
        activeNavLink,
        setActiveSubLink,
        setReplayAnimationClicked,
    } = useStore();
    const closeSidebar = () => {
        setSidebarStatus(SidebarVisibility.notShow);

        resetAnimationFrame();

        // activeNavLink.annotations
        //   ? playHotspot(activeNavLink.annotations[0])
        //   : playHotspot(resetCameraAnnotation);
        playHotspot(resetCameraAnnotation);

        setTimeout(() => {
            setActiveSubLink({} as SubLink);
        }, 500);
    };

    const replayAnimation = () => {
        setReplayAnimationClicked(true);
        if (activeSubLink.animations && activeSubLink.animations.length) {
            playCustomAnimation();
        }
    };

    return (
        <div
            className={`c-sidebar ${
                sidebarStatus === SidebarVisibility.show
                    ? "show-sidebar-motion"
                    : sidebarStatus === SidebarVisibility.notShow &&
                      "hide-sidebar-motion"
            }`}
        >
            <>
                <button
                    className={`button button--secondary button--xlarge button--thick-border ${
                        animationIsPlaying && "button--disabled"
                    }`}
                    onClick={closeSidebar}
                    disabled={animationIsPlaying}
                >
                    Return to 3D overview
                </button>
                <SidebarContent />
                {activeSubLink.animations &&
                    activeSubLink.animations.length &&
                    activeSubLink.animationSequences &&
                    activeSubLink.animationSequences.length && (
                        <button
                            className={`button button--secondary button--xlarge button--thick-border ${
                                animationIsPlaying ? "button--disabled" : ""
                            }`}
                            onClick={replayAnimation}
                            disabled={animationIsPlaying}
                        >
                            Replay Animation
                        </button>
                    )}
            </>
        </div>
    );
};

export default Sidebar;
