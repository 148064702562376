import React from "react";
import { useStore } from "src/store";
import SubLinkComponent from "./SubLink.component";

type SubLinkListProps = {};

const SubLinkList: React.FC<SubLinkListProps> = () => {
    const { activeNavLink, dropdown, hasDropdown, hasNavLinks } = useStore();
    if (hasDropdown && !hasNavLinks) {
        return (
            <nav
                className={`c-cytiva__sub ${
                    dropdown ? "c-cytiva__sub-dp" : ""
                }`}
            >
                <ul
                    className={`c-cytiva__sub-list  ${
                        dropdown
                            ? "c-cytiva__sub-list__dropdown--active"
                            : " c-cytiva__sub-list-dp"
                    } `}
                >
                    {activeNavLink.subLinks.map((subLink) => (
                        <React.Fragment key={subLink.id}>
                            <SubLinkComponent subLink={subLink} />
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        );
    } else {
        return (
            <nav className="c-cytiva__sub">
                <ul className={`c-cytiva__sub-list`}>
                    {activeNavLink.subLinks.map((subLink) => (
                        <React.Fragment key={subLink.id}>
                            <SubLinkComponent subLink={subLink} />
                        </React.Fragment>
                    ))}
                </ul>
            </nav>
        );
    }
};

export default SubLinkList;
