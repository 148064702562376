import { cameraMoveTiming } from "../constants";
import { useStore } from "../store";
import { Annotation, SidebarVisibility } from "../types/index.d";

export const storeAnnotations = () => {
    const api = useStore.getState().apiref;
    api.getAnnotationList((err: any, annotations: Annotation[]) => {
        if (!err) {
            useStore.setState({ annotations: annotations });
        }
    });
};

export const playHotspot = (annotationName: string) => {
    const api = useStore.getState().apiref;
    const annotation = useStore
        .getState()
        .annotations.find((annotation) => annotation.name === annotationName);

    api.setCameraLookAt(
        annotation?.eye,
        annotation?.target,
        cameraMoveTiming,
        function (err: any) {
            if (!err) {
            }
        }
    );
};

export const onclickHotspotOnSidebar = (id: string) => {
    const { links, setActiveSubLink, setSidebarStatus } = useStore.getState();

    const sublink = links[0].subLinks.find((sub) => sub.id === id);
    if (sublink) {
        const delayToOpenSidebar = sublink.annotations
            ? cameraMoveTiming * 1000
            : 0;

        setActiveSubLink(sublink);
        if (sublink.annotations) {
            playHotspot(sublink.annotations[0]);
            setSidebarStatus(SidebarVisibility.notShow);
        }

        setTimeout(() => {
            setSidebarStatus(SidebarVisibility.show);
        }, delayToOpenSidebar);
    }
};
