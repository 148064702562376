import React from "react";
import { Title, Text } from "../dynamic.components";
import { useStore } from "src/store";

export const AtexRating: React.FC = () => {
    const { activeSubLink } = useStore();

    return (
        <>
            <Title title={activeSubLink.text} />

            <div className="c-sidebar__body">
                <Text text="The NanoAssemblr® modular commercial formulation skid is ATEX and IECEx rated for use in hazardous locations to ensure safety when handling flammable solvents during lipid nanoparticle formulation. The system is built with automation to purge and blanket the flow path with nitrogen." />
            </div>
        </>
    );
};
