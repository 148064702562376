import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { produce } from "immer";
import {
    Animation,
    Annotation,
    CustomLink,
    CytivaState,
    SubLink,
    Overlays,
    SidebarVisibility,
    TreeDModel,
    StackMaterial,
} from "../types/index.d";
import { links } from "../data";

export const useStore = create<CytivaState, any>(
    devtools((set) => ({
        links: links,
        hasNavLinks: false,
        apiref: "",
        setApiref: (api: any) =>
            set(
                produce((state: CytivaState) => {
                    state.apiref = api;
                }),
                false,
                "Sketchfab Api is set in store"
            ),
        viewIsReady: false,
        setViewIsReady: (isReady: boolean) =>
            set(
                produce((state: CytivaState) => {
                    state.viewIsReady = isReady;
                }),
                false,
                `View is ready set ${isReady}`
            ),
        sidebarStatus: SidebarVisibility.notSet,
        animations: [],
        setAnimations: (animations: Animation[]) =>
            set(
                produce((state: CytivaState) => {
                    state.animations = animations;
                }),
                false,
                "Animations set in store"
            ),
        annotations: [],
        setAnnotations: (annotations: Annotation[]) =>
            set(
                produce((state: CytivaState) => {
                    state.annotations = annotations;
                }),
                false,
                "Annotation set in store"
            ),
        activeNavLink: links[0],
        setActiveNavLink: (navLink: CustomLink) =>
            set(
                produce((state: CytivaState) => {
                    state.activeNavLink = navLink;
                }),
                false,
                `Nav link ${navLink.title} set active`
            ),
        activeSubLink: links[0].subLinks[0],
        setActiveSubLink: (subLink: SubLink) =>
            set(
                produce((state: CytivaState) => {
                    state.activeSubLink = subLink;
                }),
                false,
                `Sub link ${subLink.text} set active`
            ),
        setSidebarStatus: (status: SidebarVisibility) =>
            set(
                produce((state: CytivaState) => {
                    state.sidebarStatus = status;
                }),
                false,
                `Sidebar status: ${status}`
            ),
        materials: [],
        stackLights: [
            {
                name: "Single-use NxGen™ cartridge",
                material: StackMaterial.hotspot_cartrige,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#158395",
            },
            {
                name: "Aqueous line",
                material: StackMaterial.hotspot_aqueous,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#29C1DB",
            },
            {
                name: "Organic line",
                material: StackMaterial.hotspot_organic,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#EC7B37",
            },
            {
                name: "Dilution line",
                material: StackMaterial.hotspot_dilution,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#253B7E",
            },
            {
                name: "Waste outlet",
                material: StackMaterial.hotspot_waste,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#06B2AD",
            },
            {
                name: "Formulation outlet",
                material: StackMaterial.hotspot_output,
                status: false,
                lightOn: "#aeaeaf",
                lightOff: "#6E3C97",
            },
        ],
        toggleLamp: (stackMaterial: StackMaterial) =>
            set(
                produce((state: CytivaState) => {
                    state.stackLights.find(
                        (st) => st.material === stackMaterial
                    )!.status = !state.stackLights.find(
                        (st) => st.material === stackMaterial
                    )!.status;
                }),
                false,
                `Lamp name: ${stackMaterial}`
            ),
        hasDropdown: true,
        dropdown: false,
        setDropdown: (d: boolean) =>
            set(
                produce((state: CytivaState) => {
                    state.dropdown = d;
                }),
                false,
                `Set dropdown to ${d}`
            ),
        overlays: {
            trademark: false,
            help: false,
        },
        toggleDropdown: () =>
            set(
                produce((state: CytivaState) => {
                    state.dropdown = !state.dropdown;
                }),
                false,
                "Toggle dropdown"
            ),
        toggleOverlay: (overlay: Overlays) =>
            set(
                produce((state: CytivaState) => {
                    state.overlays[overlay] = !state.overlays[overlay];
                }),
                false,
                `Overlay ${overlay} toggled`
            ),
        closeOverlay: () =>
            set(
                produce((state: CytivaState) => {
                    if (state.overlays.help) state.overlays.help = false;
                    if (state.overlays.trademark)
                        state.overlays.trademark = false;
                }),
                false,
                "Close overlay"
            ),
        animationIsPlaying: false,
        animationStopped: true,
        setAnimationStopped: (status: boolean) =>
            set(
                produce((state: CytivaState) => {
                    state.animationStopped = status;
                }),
                false,
                `Animation stop status ${status}`
            ),
        footprintVisible: false,
        setFootprintVisible: (footprintShow: boolean) =>
            set(
                produce((state: CytivaState) => {
                    state.footprintVisible = footprintShow;
                }),
                false,
                `Footer print set ${footprintShow}`
            ),
        activeModel: TreeDModel.one_S_Plus,
        changeModel: (newModel: TreeDModel) =>
            set(
                produce((state: CytivaState) => {
                    state.activeModel = newModel;
                }),
                false,
                `Change model`
            ),
        replayAnimationclicked: false,
        setReplayAnimationClicked: (b: boolean) =>
            set(
                produce((state: CytivaState) => {
                    state.replayAnimationclicked = b;
                }),
                false,
                `Replay animation set to ${b}`
            ),
    }))
);
