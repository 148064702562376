import React from "react";
import { Title, Text, Spacer, List, Video } from "../dynamic.components";
import { useStore } from "src/store";
import goliath_intro from "src/assets/video/11026_Goliath_Modularity_Hotspot_Video.mp4";

export const Configuration: React.FC = () => {
    const { activeSubLink, links, setActiveSubLink, setSidebarStatus } =
        useStore();

    return (
        <>
            <Title title={activeSubLink.text} />

            <div className="c-sidebar__body">
                <Text text="The NanoAssemblr® modular commercial formulation skid has a flexible, configurable design consisting of 1-8 mixing modules and aqueous, organic, and collection vessels sized to process requirements. The scalable number of mixing modules enables high-flow, large-volume production of lipid nanoparticles (LNPs)." />
                <Spacer x={2} />
                <List
                    listArray={[
                        "Total flow rates from 48 L/h to 384 L/h",
                        "Total volumes from 400 L to 3200 L",
                    ]}
                />
                <Spacer x={2} />
                <Video src={goliath_intro} />
            </div>
        </>
    );
};
