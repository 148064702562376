import React from "react";
import { useStore } from "src/store";
import { Spacer } from "../sidebar/contents/dynamic.components";

const TradeMark = ({}) => {
    const { closeOverlay } = useStore();
    return (
        <>
            <div
                className="c-cytiva__trademark-section"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="c-cytiva__trademark-top">
                    <img
                        className="c-cytiva__trademark-top__logo"
                        src="android-chrome-192x192.png"
                        width="40"
                        alt="Cytiva logo"
                    />
                    <button
                        className="c-cytiva__trademark-top__close"
                        onClick={closeOverlay}
                    >
                        X
                    </button>
                </div>
                <p className="c-cytiva__trademark-text">
                    Precision NanoSystems and the logo, NanoAssemblr,
                    NanoAssemblr Blaze and Create Transformative Medicines, are
                    registered trademarks of Precision NanoSystems or an
                    affiliate doing business as Precision NanoSystems.
                    <Spacer />
                    GenVoy, NxGen, Spark, Ignite, Ignite+ and Blaze+, are common
                    law trademarks of Precision NanoSystems or an affiliate
                    doing business as Precision NanoSystems.
                    <Spacer />
                    For local office contact information, visit:{" "}
                    <a
                        className="c-cytiva__trademark-link"
                        style={{ textDecoration: "none" }}
                        href="https://www.precisionnanosystems.com/contact-us"
                        target="_blank"
                        title="Contact us"
                    >
                        precisionnanosystems.com/contact
                    </a>
                    <Spacer />© 2023 Precision NanoSystems. All Rights Reserved.
                </p>
            </div>
        </>
    );
};

export default TradeMark;
