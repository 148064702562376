import React, { useEffect } from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { componentAnimationReadyToPlay } from "src/utils/helper";
import { setAnimationFrame } from "src/services/animations";

export const InlineDilution: React.FC = () => {
    const { activeSubLink, sidebarStatus } = useStore();

    useEffect(() => {
        if (componentAnimationReadyToPlay("1-6")) {
            activeSubLink.staticFrameWithHotspot &&
                setAnimationFrame(activeSubLink.staticFrameWithHotspot[0]);
        }
    }, [sidebarStatus]);

    return (
        <>
            <Title title={activeSubLink.text} />

            <div className="c-sidebar__body">
                <Text text="Automated in-line dilution of lipid nanoparticles lowers the ethanol content immediately following formulation. High turndown pumps enable a wide range of dilution flow rates." />
                <Spacer />
                <List listArray={["Dilution ratios from 1:1 to 10:1"]} />
            </div>
        </>
    );
};
