import React from "react";
import { cameraMoveTiming } from "src/constants";
import { switchToModel } from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "src/store";
import { SubLink, TreeDModel, SidebarVisibility } from "src/types/index.d";

type SubLinkProps = {
    subLink: SubLink;
};

const SubLinkComponent: React.FC<SubLinkProps> = ({ subLink }) => {
    const { setActiveSubLink, setSidebarStatus, setDropdown } = useStore();

    const delayToOpenSidebar = subLink.annotations
        ? cameraMoveTiming * 1000
        : 0;

    const onSubLinkClick = () => {
        setActiveSubLink(subLink);
        setDropdown(false);
        if (subLink.annotations) {
            playHotspot(subLink.annotations[0]);
        }

        setTimeout(() => {
            setSidebarStatus(SidebarVisibility.show);
        }, delayToOpenSidebar);
    };

    return (
        <li className="c-cytiva__sub-list__item">
            <button
                className="button--fixwidth-large button button--primary button--large button--inline"
                onClick={onSubLinkClick}
            >
                {subLink.text}
            </button>
        </li>
    );
};

export default SubLinkComponent;
