import { CustomLink } from "../types";

export enum SubLinkId {
    configuration = "1-1",
    integratedAutomation = "1-2",
    nxGenCartridge = "1-3",
    inlineMonitoring = "1-4",
    atexRating = "1-5",
    inlineDilution = "1-6",
    displacementPumps = "1-7",
    // hazardousLocationsRated = "1-8",
}

export const links: CustomLink[] = [
    {
        id: "1",
        title: "HMI",
        hasSublinks: true,
        annotations: ["HMI"],
        subLinks: [
            {
                id: "1-1",
                text: "Configuration",
                annotations: ["gol_top-side"],
            },
            {
                id: "1-2",
                text: "Integrated PLC automation",
                annotations: ["gol_IntegrPlcAut"],
                changeMaterials: ["HILIGHT_1"],
            },
            {
                id: "1-3",
                text: "NxGen™ cartridge",
                animations: ["Scene"],
                annotations: ["gol_mixC_OPEN"],
                staticFrameWithHotspot: [
                    {
                        annotation: "gol_mixC_OPEN",
                        frame: 0.1,
                    },
                ],
                extras: {
                    animations: ["Scene"],
                    annotations: [
                        "gol_mixC_OPEN",
                        "gol_mixC_EXPAND",
                        "gol_mixC_OPEN",
                    ],
                    animationSequences: [
                        [0.1, 4.2],
                        [4.8, 7],
                        [7.88, 13.32],
                    ],
                    staticFrameWithHotspot: [
                        {
                            annotation: "gol_mix_CARTRIDGE",
                            frame: 7,
                        },
                    ],
                },
            },
            {
                id: "1-4",
                text: "In-line process monitoring",
                annotations: ["gol_In-lineProcMon"],
                extras: {
                    annotations: [
                        "gol_FlowMeter",
                        "gol_PressTransd",
                        "gol_TempSens",
                    ],
                    changeMaterials: ["HILIGHT_2", "HILIGHT_4", "HILIGHT_3"],
                },
            },
            {
                id: "1-5",
                text: "Hazardous location rated",
                annotations: ["gol_ATEX-IECEx"],
            },
            {
                id: "1-6",
                text: "In-line dilution",
                annotations: ["gol_In-lineDilution"],
                animations: ["Scene"],
                staticFrameWithHotspot: [
                    { annotation: "gol_In-lineDilution", frame: 15.5 },
                ],
            },
            {
                id: "1-7",
                text: "Positive displacement pumps",
                annotations: ["gol_PosDisplPumps"],
                extras: {
                    annotations: ["gol_AutomGEMU"],
                },
            },
            // {
            //     id: "1-8",
            //     text: "Hazardous locations rated",
            //     annotations: ["gol_PressTransd"],
            // },
        ],
    },
];
