import React, { useEffect } from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { onclickHotspotOnSidebar } from "src/services/annotations";
import { changeMaterialOpacityByName } from "src/services/materials-textures";
import { SidebarVisibility } from "src/types/index.d";

export const IntegratedAutomation: React.FC = () => {
    const { activeSubLink, sidebarStatus } = useStore();

    useEffect(() => {
        if (sidebarStatus === SidebarVisibility.show) {
            activeSubLink.changeMaterials &&
                changeMaterialOpacityByName(
                    activeSubLink.changeMaterials[0],
                    0.2
                );
        }

        if (sidebarStatus === SidebarVisibility.notShow) {
            activeSubLink.changeMaterials &&
                changeMaterialOpacityByName(
                    activeSubLink.changeMaterials[0],
                    0
                );
        }
    }, [sidebarStatus]);

    return (
        <>
            <Title title={activeSubLink.text} />

            <div className="c-sidebar__body">
                <Text text="The NanoAssemblr® modular commercial formulation skid is fully automated with in-line process monitoring and local and remote data access. Electronic batch records are enabled by intuitive 21 CFR Part 11 compliant software." />
                <Spacer x={2} />
                <Text text="Automated features include:" />
                <Spacer />
                <List
                    listArray={[
                        "Electronic batch records",
                        "Centralized automation control using local PC client",
                        "Data communication with other systems using Open Platform Communications-Unified Architecture (OPC-UA)",
                        "Templated recipes that reduce start-up time",
                        "In-line dilution ",
                        "In-line process monitoring including flow rate, pressure, and temperature",
                    ]}
                />
                <Spacer x={3} />
                <button
                    className="button--fixwidth-large button button--primary button--large button--inline"
                    onClick={() => {
                        onclickHotspotOnSidebar("1-6");
                        activeSubLink.changeMaterials &&
                            changeMaterialOpacityByName(
                                activeSubLink.changeMaterials[0],
                                0
                            );
                    }}
                >
                    In-line dilution
                </button>
                <Spacer />
                <button
                    className="button--fixwidth-large button button--primary button--large button--inline"
                    onClick={() => {
                        onclickHotspotOnSidebar("1-4");
                        activeSubLink.changeMaterials &&
                            changeMaterialOpacityByName(
                                activeSubLink.changeMaterials[0],
                                0
                            );
                    }}
                >
                    In-line process monitoring
                </button>
            </div>
        </>
    );
};
