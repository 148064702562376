import React, { useEffect, useState } from "react";
import { Title, Text, Spacer, Video } from "../dynamic.components";
import { useStore } from "src/store";
import { playOneAnimation, setAnimationFrame } from "src/services/animations";
import play from "src/assets/svg/play.svg";
import goliath_mixing from "src/assets/video/11026_Goliath_Mixing_Hotspot_Video_V04.mp4";
import {
    componentAnimationReadyToPlay,
    componentAnimationReadyToStop,
} from "src/utils/helper";

export const NxGenCartridge: React.FC = () => {
    const [state, setState] = useState(0);

    const {
        activeSubLink: { text, extras },
        setReplayAnimationClicked,
        animationIsPlaying,
        sidebarStatus,
        setAnimationStopped,
    } = useStore();

    const onClickCartridge = () => {
        if (extras?.staticFrameWithHotspot) {
            setState(4);
            setAnimationFrame(extras.staticFrameWithHotspot[0]);
        }
    };
    useEffect(() => {
        if (componentAnimationReadyToPlay("1-3")) {
            setState(10);
        }
        if (componentAnimationReadyToStop("1-3")) {
            setAnimationStopped(true);
            setState(10);
        }
    }, [sidebarStatus]);

    return (
        <>
            <Title title={`Single-use ${text}`} />
            <div className="c-sidebar__body">
                <Text text="The single-use NxGen™ cartridge enables controlled and reproducible mixing conditions. The same scalable mixing architecture is maintained across the NanoAssemblr® family, resulting in consistent critical quality attributes (CQAs) throughout development to commercial manufacturing." />
                <Spacer />
                <Text text="Single-use technology reduces the risk of cross-contamination between batches and drug products." />
            </div>
            <Spacer x={3} />
            {extras &&
                extras.annotations &&
                extras.annotations.map((annotation, index) => (
                    <React.Fragment key={index}>
                        <button
                            style={{ width: "300px" }}
                            onClick={() => {
                                setReplayAnimationClicked(false);
                                setState(index + 1);
                                playOneAnimation(
                                    extras?.annotations?.[index],
                                    extras?.animationSequences?.[index],
                                    true,
                                    true
                                );
                            }}
                            className={`button button--secondary button--large button--fixwidth ${
                                animationIsPlaying && state === index + 1
                                    ? "button--disabled"
                                    : state === index + 1 && "button--hover"
                            }`}
                            disabled={animationIsPlaying}
                        >
                            <span style={{ margin: "auto" }}>
                                {index === 0 && "Cartridge uninstalled"}
                                {index === 1 && "Cartridge disassembled"}
                                {index === 2 && "Cartridge installed"}
                            </span>
                            <img
                                className={`play-icon ${
                                    animationIsPlaying && state === index + 1
                                        ? "play-icon-animate"
                                        : ""
                                }`}
                                src={play}
                                alt="play animation"
                            />
                        </button>
                        <Spacer />
                    </React.Fragment>
                ))}

            <button
                style={{ width: "300px" }}
                onClick={onClickCartridge}
                className={`button button--secondary button--large button--fixwidth ${
                    state === 4 ? "button--hover" : ""
                }`}
                disabled={animationIsPlaying}
            >
                Cartridge chip
            </button>
            <Spacer x={2} />
            <Video src={goliath_mixing} />
        </>
    );
};
