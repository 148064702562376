import React, { useEffect, useState } from "react";
import { Title, Text, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import { playHotspot } from "src/services/annotations";

export const DisplacementPumps: React.FC = () => {
    // const [state, setState] = useState(0);
    const {
        activeSubLink: { text, extras, annotations },
        sidebarStatus,
    } = useStore();

    // useEffect(() => {
    //     if (state !== 0) {
    //         setState(0);
    //     }
    // }, [sidebarStatus]);

    return (
        <>
            <Title title={text} />

            <div className="c-sidebar__body">
                <Text text="The NanoAssemblr® modular commercial formulation skid uses positive displacement pumps specifically designed for sanitary and hygienic applications, reducing the risk of cross-contamination between batches. Positive displacement pumps result in accurate and repeatable fluid flow." />
                {/* <Spacer x={3} />
                <button
                    style={{ width: "300px" }}
                    onClick={() => {
                        if (annotations) {
                            setState(0);
                            playHotspot(annotations[0]);
                        }
                    }}
                    className={`button button--secondary button--large button--fixwidth ${
                        state === 0 ? "button--hover" : ""
                    }`}
                >
                    {text}
                </button>
                <Spacer />
                <button
                    style={{ width: "300px" }}
                    onClick={() => {
                        if (extras?.annotations) {
                            playHotspot(extras?.annotations[0]);
                            setState(1);
                        }
                    }}
                    className={`button button--secondary button--large button--fixwidth ${
                        state === 1 ? "button--hover" : ""
                    }`}
                >
                    Automation (GEMU valves)
                </button> */}
            </div>
        </>
    );
};
